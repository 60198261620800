.container {
    margin: 0;
    padding: 0;
    height: 100vh;
    background: white;
    background-attachment: fixed;
  }

  .calendarBigContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: white;
    background-attachment: fixed;
  }

  .calendarBigContainer .rbc-calendar {
    width: 90%; /* Dostosuj szerokość kalendarza do kontenera */
  }