body{
    margin: 0;
    padding: 0;
    height: 100vh; /* 100% wysokości widoku (viewport) */

    background: linear-gradient(360deg, #236399 0%, #fff 100%);
    background-attachment: fixed;
}

/* .container:not(.calendar-big-container) {

    display: flex;
    justify-content: center;
    align-items: center;
  height: 100vh;
  background: white;
  background-attachment: fixed;
  } */