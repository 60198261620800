/* body {
    display: flex;
    margin-top: 50px;
    justify-content: center;
    background-color: #FFFCFF;
  } */
  .button {
    margin-right: 2.5px;
    margin-left: 2.5px;
    width: 75px;
    cursor: pointer;
    box-shadow: 0px 0px 2px gray;
    border: none;
    outline: none;
    padding: 5px;
    border-radius: 5px;
    color: white;
    font-size: 10px;
  }

  #header {
    padding: 10px;
    color: #d36c6c;
    font-size: 26px;
    font-family: sans-serif;
    display: flex;
    justify-content: space-between;
  }
  #header button {
    background-color:#92a1d1;
  }
  #container {
    width: 600px;
    margin: auto;
  }
  #weekdays {
    width: 100%;
    display: flex;
    color: #247BA0;
    font-size: 10px;
  }
  #weekdays div {
    width: 82px;
    padding: 10px;
  }

  @media (max-width: 600px) {
    #weekdays div {
      white-space: nowrap; /* Zapobiega zawijaniu tekstu na nową linię */
      overflow: hidden; /* Ukrywa nadmiarowy tekst */
      text-overflow: ellipsis; /* Dodaje trzy kropki na końcu, gdy tekst jest zbyt długi */
      width: 53px; /* Dostosuj szerokość do potrzeb */
      font-size: 8px; /* Dostosuj rozmiar czcionki do potrzeb */
    }
  }


  #calendar {
    width: 100%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
  }

  .day {
    font-size: 12px;
    width: 78px;
    padding: 5px;
    height: 78px;
    cursor: pointer;
    box-sizing: border-box;
    background-color: white;
    margin: 2px;
    box-shadow: 0px 0px 3px #CBD4C2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
  }

  @media (max-width: 600px) {
    .day {
        font-size: 8px;
        width: 50px;
        height: 80px;
        padding: 3px;
    }

    #container {
      width: 392px;
      margin: auto;
    }

    .eventProba {
      white-space: nowrap; /* Zapobiega zawijaniu tekstu na nową linię */
      overflow: hidden; /* Ukrywa nadmiarowy tekst */
      text-overflow: ellipsis; /* Dodaje trzy kropki na końcu, gdy tekst jest zbyt długi */
    }

}



    .day:hover {
    background-color: #e8faed;
  }

  .day + .currentDay {
    background-color:#d1a2f3;
  }

   .event {
    font-size: 8px;
    padding: 3px;
    background-color: #58bae4;
    color: white;
    border-radius: 5px;
    max-height: 45px;
    overflow: hidden;
    position: absolute;
    width: 90%;
    top: 100%;
    left: 5%;
    transform: translateY(-110%);
  }

  .eventProba {
    font-size: 8px;
    padding: 3px;
    background-color: #cfed28;
    color: black;
    border-radius: 5px;
    max-height: 45px;
    overflow: hidden;
    position: absolute;
    top: 70%;
    left: 5%;
    width: 90%;
    transform: translateY(-100%);
  }

  /* .event,
.eventProba {
  font-size: 8px;
  padding: 3px;
  border-radius: 5px;
  max-height: 45px;
  overflow: hidden;
  width: 90%;
  margin-left: 5%;
}

.event {
  background-color: #58bae4;
  color: white;
  transform: translateY(0);
}

.eventProba {
  background-color: #cfed28;
  color: black;
  transform: translateY(10%);
} */

  .event-old {
    font-size: 10px;
    padding: 3px;
    background-color: #58bae4;
    color: white;
    border-radius: 5px;
    max-height: 55px;
    overflow: hidden;
    position: absolute;
    top: 50%; /* Przesuń wertykalnie na środek */
    left: 20%; /* Przesuń poziomo na środek */
    transform: translate(-50%, -20%); /* Skoryguj położenie na środek */
  }
  .padding {
    cursor: default !important;
    background-color: #FFFCFF !important;
    box-shadow: none !important;
  }
  #newEventModal, #deleteEventModal {
    z-index: 20;
    padding: 25px;
    background-color: #e8f4fa;
    box-shadow: 0px 0px 3px black;
    border-radius: 5px;
    width: 350px;
    top: 100px;
    left: calc(50% - 175px);
    position: absolute;
    font-family: sans-serif;
  }
  #eventTitleInput {
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 25px;
    border-radius: 3px;
    outline: none;
    border: none;
    box-shadow: 0px 0px 3px gray;
  }
  #eventTitleInput.error {
    border: 2px solid red;
  }
  #cancelButton, #deleteButton {
    background-color: #d36c6c;
  }
  #saveButton, #closeButton {
    background-color: #92a1d1;
  }
  #eventText {
    font-size: 14px;
  }
  #modalBackDrop {
    top: 0px;
    left: 0px;
    z-index: 10;
    width: 100vw;
    height: 100vh;
    position: absolute;
    background-color: rgba(0,0,0,0.8);
  }
